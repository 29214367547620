.SubscriptionCreateAccount {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.SubscriptionCreateAccount h1 {
    font-size: 24px;
    color: black;
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    margin: 0 0 16px;
}

.SubscriptionCreateAccount p,
.SubscriptionCreateAccount button {
    font-size: 14px;
    color: dimgray;
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    margin: 0 0 16px;
    background-color: transparent;
}

.SubscriptionCreateAccount button {
    color: rgb(var(--color-blue-4));
    font-weight: 400;
}

.SubscriptionCreateAccount h3 {
    font-size: 16px;
    color: black;
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
    padding: 8px 0 16px;
}

.SubscriptionCreateAccount .userDataTitle {
    margin-top: 32px;
}

.SubscriptionCreateAccount input {
    font-family: 'Roboto Mono', monospace;
    background: none;
    color: black;
    border: 1px solid black;
    padding: 6px 10px;
    width: 100%;
}

.SubscriptionCreateAccount input:not(:first-of-type) {
    margin-top: 16px;
}

.SubscriptionCreateAccount .submitContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.SubscriptionCreateAccount .submit {
    font-family: 'Roboto Mono', monospace;
    background: none;
    font-size: 24px;
    align-self: flex-end;
    color: #000;
    border: none;
    box-shadow: none;
    margin: 15px 0 0;
    cursor: pointer;
    font-weight: 300;
}

.SubscriptionCreateAccount .submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.SubscriptionCreateAccount .loading {
    position: absolute;
    right: 320px;
    top: 24px;
    margin: auto;
    width: 20px;
    height: 20px;
}

.SubscriptionCreateAccount .gradeList {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 16px;
}

.SubscriptionCreateAccount .gradeList .grade {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.SubscriptionCreateAccount .gradeList label {
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 4px;
    text-align: center;
    color: black;
}

.SubscriptionCreateAccount .gradeList input[type=checkbox] {
    visibility: hidden;
    position: absolute;
}

.SubscriptionCreateAccount .gradeList .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid black;
}

.SubscriptionCreateAccount .gradeList.error .checkmark {
    border: 1px solid rgb(var(--color-red));
}

.SubscriptionCreateAccount .gradeList input:checked~.checkmark {
    background-color: rgb(var(--color-yellow));
    border: 1px solid rgb(var(--color-yellow));
    z-index: 0;
}

.SubscriptionCreateAccount .gradeList .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.SubscriptionCreateAccount .gradeList input:checked~.checkmark:after {
    display: none;
}

.SubscriptionCreateAccount .consent {
    margin: 24px 0 8px;
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
}

.SubscriptionCreateAccount .userData.error {
    border: 1px solid rgb(var(--color-red));
}

.SubscriptionCreateAccount .errorMessage {
    color: rgb(var(--color-red));
}

@media (max-width: 767px) {
    .SubscriptionCreateAccount .gradeList {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) {
    .SubscriptionCreateAccount .gradeList {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 992px) {
    .SubscriptionCreateAccount .gradeList {
        grid-template-columns: repeat(9, 1fr);
    }
}